module.exports = [
  {
      "provinceCode": "110000",
      "label": "北京市",
      "value": "北京市",
      "children": [
          {
              "cityCode": "110100",
              "label": "北京市",
              "value": "北京市"
          },
          {
              "cityCode": "110200",
              "label": "北京市辖县",
              "value": "北京市辖县"
          }
      ]
  },
  {
      "provinceCode": "120000",
      "label": "天津市",
      "value": "天津市",
      "children": [
          {
              "cityCode": "120100",
              "label": "天津市",
              "value": "天津市"
          },
          {
              "cityCode": "120200",
              "label": "天津市辖县",
              "value": "天津市辖县"
          }
      ]
  },
  {
      "provinceCode": "130000",
      "label": "河北省",
      "value": "河北省",
      "children": [
          {
              "cityCode": "130100",
              "label": "石家庄市",
              "value": "石家庄市"
          },
          {
              "cityCode": "130200",
              "label": "唐山市",
              "value": "唐山市"
          },
          {
              "cityCode": "130300",
              "label": "秦皇岛市",
              "value": "秦皇岛市"
          },
          {
              "cityCode": "130400",
              "label": "邯郸市",
              "value": "邯郸市"
          },
          {
              "cityCode": "130500",
              "label": "邢台市",
              "value": "邢台市"
          },
          {
              "cityCode": "130600",
              "label": "保定市",
              "value": "保定市"
          },
          {
              "cityCode": "130700",
              "label": "张家口市",
              "value": "张家口市"
          },
          {
              "cityCode": "130800",
              "label": "承德市",
              "value": "承德市"
          },
          {
              "cityCode": "130900",
              "label": "沧州市",
              "value": "沧州市"
          },
          {
              "cityCode": "131000",
              "label": "廊坊市",
              "value": "廊坊市"
          },
          {
              "cityCode": "131100",
              "label": "衡水市",
              "value": "衡水市"
          }
      ]
  },
  {
      "provinceCode": "140000",
      "label": "山西省",
      "value": "山西省",
      "children": [
          {
              "cityCode": "140100",
              "label": "太原市",
              "value": "太原市"
          },
          {
              "cityCode": "140200",
              "label": "大同市",
              "value": "大同市"
          },
          {
              "cityCode": "140300",
              "label": "阳泉市",
              "value": "阳泉市"
          },
          {
              "cityCode": "140400",
              "label": "长治市",
              "value": "长治市"
          },
          {
              "cityCode": "140500",
              "label": "晋城市",
              "value": "晋城市"
          },
          {
              "cityCode": "140600",
              "label": "朔州市",
              "value": "朔州市"
          },
          {
              "cityCode": "140700",
              "label": "晋中市",
              "value": "晋中市"
          },
          {
              "cityCode": "140800",
              "label": "运城市",
              "value": "运城市"
          },
          {
              "cityCode": "140900",
              "label": "忻州市",
              "value": "忻州市"
          },
          {
              "cityCode": "141000",
              "label": "临汾市",
              "value": "临汾市"
          },
          {
              "cityCode": "141100",
              "label": "吕梁市",
              "value": "吕梁市"
          }
      ]
  },
  {
      "provinceCode": "150000",
      "label": "内蒙古自治区",
      "value": "内蒙古自治区",
      "children": [
          {
              "cityCode": "150100",
              "label": "呼和浩特市",
              "value": "呼和浩特市"
          },
          {
              "cityCode": "150200",
              "label": "包头市",
              "value": "包头市"
          },
          {
              "cityCode": "150300",
              "label": "乌海市",
              "value": "乌海市"
          },
          {
              "cityCode": "150400",
              "label": "赤峰市",
              "value": "赤峰市"
          },
          {
              "cityCode": "150500",
              "label": "通辽市",
              "value": "通辽市"
          },
          {
              "cityCode": "150600",
              "label": "鄂尔多斯市",
              "value": "鄂尔多斯市"
          },
          {
              "cityCode": "150700",
              "label": "呼伦贝尔市",
              "value": "呼伦贝尔市"
          },
          {
              "cityCode": "150800",
              "label": "巴彦淖尔市",
              "value": "巴彦淖尔市"
          },
          {
              "cityCode": "150900",
              "label": "乌兰察布市",
              "value": "乌兰察布市"
          },
          {
              "cityCode": "152200",
              "label": "兴安盟",
              "value": "兴安盟"
          },
          {
              "cityCode": "152500",
              "label": "锡林郭勒盟",
              "value": "锡林郭勒盟"
          },
          {
              "cityCode": "152900",
              "label": "阿拉善盟",
              "value": "阿拉善盟"
          }
      ]
  },
  {
      "provinceCode": "210000",
      "label": "辽宁省",
      "value": "辽宁省",
      "children": [
          {
              "cityCode": "210100",
              "label": "沈阳市",
              "value": "沈阳市"
          },
          {
              "cityCode": "210200",
              "label": "大连市",
              "value": "大连市"
          },
          {
              "cityCode": "210300",
              "label": "鞍山市",
              "value": "鞍山市"
          },
          {
              "cityCode": "210400",
              "label": "抚顺市",
              "value": "抚顺市"
          },
          {
              "cityCode": "210500",
              "label": "本溪市",
              "value": "本溪市"
          },
          {
              "cityCode": "210600",
              "label": "丹东市",
              "value": "丹东市"
          },
          {
              "cityCode": "210700",
              "label": "锦州市",
              "value": "锦州市"
          },
          {
              "cityCode": "210800",
              "label": "营口市",
              "value": "营口市"
          },
          {
              "cityCode": "210900",
              "label": "阜新市",
              "value": "阜新市"
          },
          {
              "cityCode": "211000",
              "label": "辽阳市",
              "value": "辽阳市"
          },
          {
              "cityCode": "211100",
              "label": "盘锦市",
              "value": "盘锦市"
          },
          {
              "cityCode": "211200",
              "label": "铁岭市",
              "value": "铁岭市"
          },
          {
              "cityCode": "211300",
              "label": "朝阳市",
              "value": "朝阳市"
          },
          {
              "cityCode": "211400",
              "label": "葫芦岛市",
              "value": "葫芦岛市"
          }
      ]
  },
  {
      "provinceCode": "220000",
      "label": "吉林省",
      "value": "吉林省",
      "children": [
          {
              "cityCode": "220100",
              "label": "长春市",
              "value": "长春市"
          },
          {
              "cityCode": "220200",
              "label": "吉林市",
              "value": "吉林市"
          },
          {
              "cityCode": "220300",
              "label": "四平市",
              "value": "四平市"
          },
          {
              "cityCode": "220400",
              "label": "辽源市",
              "value": "辽源市"
          },
          {
              "cityCode": "220500",
              "label": "通化市",
              "value": "通化市"
          },
          {
              "cityCode": "220600",
              "label": "白山市",
              "value": "白山市"
          },
          {
              "cityCode": "220700",
              "label": "松原市",
              "value": "松原市"
          },
          {
              "cityCode": "220800",
              "label": "白城市",
              "value": "白城市"
          },
          {
              "cityCode": "222400",
              "label": "延边朝鲜族自治州",
              "value": "延边朝鲜族自治州"
          }
      ]
  },
  {
      "provinceCode": "230000",
      "label": "黑龙江省",
      "value": "黑龙江省",
      "children": [
          {
              "cityCode": "230100",
              "label": "哈尔滨市",
              "value": "哈尔滨市"
          },
          {
              "cityCode": "230200",
              "label": "齐齐哈尔市",
              "value": "齐齐哈尔市"
          },
          {
              "cityCode": "230300",
              "label": "鸡西市",
              "value": "鸡西市"
          },
          {
              "cityCode": "230400",
              "label": "鹤岗市",
              "value": "鹤岗市"
          },
          {
              "cityCode": "230500",
              "label": "双鸭山市",
              "value": "双鸭山市"
          },
          {
              "cityCode": "230600",
              "label": "大庆市",
              "value": "大庆市"
          },
          {
              "cityCode": "230700",
              "label": "伊春市",
              "value": "伊春市"
          },
          {
              "cityCode": "230800",
              "label": "佳木斯市",
              "value": "佳木斯市"
          },
          {
              "cityCode": "230900",
              "label": "七台河市",
              "value": "七台河市"
          },
          {
              "cityCode": "231000",
              "label": "牡丹江市",
              "value": "牡丹江市"
          },
          {
              "cityCode": "231100",
              "label": "黑河市",
              "value": "黑河市"
          },
          {
              "cityCode": "231200",
              "label": "绥化市",
              "value": "绥化市"
          },
          {
              "cityCode": "232700",
              "label": "大兴安岭地区",
              "value": "大兴安岭地区"
          }
      ]
  },
  {
      "provinceCode": "310000",
      "label": "上海市",
      "value": "上海市",
      "children": [
          {
              "cityCode": "310100",
              "label": "上海市",
              "value": "上海市"
          },
          {
              "cityCode": "310200",
              "label": "上海市辖县",
              "value": "上海市辖县"
          }
      ]
  },
  {
      "provinceCode": "320000",
      "label": "江苏省",
      "value": "江苏省",
      "children": [
          {
              "cityCode": "320100",
              "label": "南京市",
              "value": "南京市"
          },
          {
              "cityCode": "320200",
              "label": "无锡市",
              "value": "无锡市"
          },
          {
              "cityCode": "320300",
              "label": "徐州市",
              "value": "徐州市"
          },
          {
              "cityCode": "320400",
              "label": "常州市",
              "value": "常州市"
          },
          {
              "cityCode": "320500",
              "label": "苏州市",
              "value": "苏州市"
          },
          {
              "cityCode": "320600",
              "label": "南通市",
              "value": "南通市"
          },
          {
              "cityCode": "320700",
              "label": "连云港市",
              "value": "连云港市"
          },
          {
              "cityCode": "320800",
              "label": "淮安市",
              "value": "淮安市"
          },
          {
              "cityCode": "320900",
              "label": "盐城市",
              "value": "盐城市"
          },
          {
              "cityCode": "321000",
              "label": "扬州市",
              "value": "扬州市"
          },
          {
              "cityCode": "321100",
              "label": "镇江市",
              "value": "镇江市"
          },
          {
              "cityCode": "321200",
              "label": "泰州市",
              "value": "泰州市"
          },
          {
              "cityCode": "321300",
              "label": "宿迁市",
              "value": "宿迁市"
          }
      ]
  },
  {
      "provinceCode": "330000",
      "label": "浙江省",
      "value": "浙江省",
      "children": [
          {
              "cityCode": "330100",
              "label": "杭州市",
              "value": "杭州市"
          },
          {
              "cityCode": "330200",
              "label": "宁波市",
              "value": "宁波市"
          },
          {
              "cityCode": "330300",
              "label": "温州市",
              "value": "温州市"
          },
          {
              "cityCode": "330400",
              "label": "嘉兴市",
              "value": "嘉兴市"
          },
          {
              "cityCode": "330500",
              "label": "湖州市",
              "value": "湖州市"
          },
          {
              "cityCode": "330600",
              "label": "绍兴市",
              "value": "绍兴市"
          },
          {
              "cityCode": "330700",
              "label": "金华市",
              "value": "金华市"
          },
          {
              "cityCode": "330800",
              "label": "衢州市",
              "value": "衢州市"
          },
          {
              "cityCode": "330900",
              "label": "舟山市",
              "value": "舟山市"
          },
          {
              "cityCode": "331000",
              "label": "台州市",
              "value": "台州市"
          },
          {
              "cityCode": "331100",
              "label": "丽水市",
              "value": "丽水市"
          }
      ]
  },
  {
      "provinceCode": "340000",
      "label": "安徽省",
      "value": "安徽省",
      "children": [
          {
              "cityCode": "340100",
              "label": "合肥市",
              "value": "合肥市"
          },
          {
              "cityCode": "340200",
              "label": "芜湖市",
              "value": "芜湖市"
          },
          {
              "cityCode": "340300",
              "label": "蚌埠市",
              "value": "蚌埠市"
          },
          {
              "cityCode": "340400",
              "label": "淮南市",
              "value": "淮南市"
          },
          {
              "cityCode": "340500",
              "label": "马鞍山市",
              "value": "马鞍山市"
          },
          {
              "cityCode": "340600",
              "label": "淮北市",
              "value": "淮北市"
          },
          {
              "cityCode": "340700",
              "label": "铜陵市",
              "value": "铜陵市"
          },
          {
              "cityCode": "340800",
              "label": "安庆市",
              "value": "安庆市"
          },
          {
              "cityCode": "341000",
              "label": "黄山市",
              "value": "黄山市"
          },
          {
              "cityCode": "341100",
              "label": "滁州市",
              "value": "滁州市"
          },
          {
              "cityCode": "341200",
              "label": "阜阳市",
              "value": "阜阳市"
          },
          {
              "cityCode": "341300",
              "label": "宿州市",
              "value": "宿州市"
          },
          {
              "cityCode": "341400",
              "label": "巢湖市",
              "value": "巢湖市"
          },
          {
              "cityCode": "341500",
              "label": "六安市",
              "value": "六安市"
          },
          {
              "cityCode": "341600",
              "label": "亳州市",
              "value": "亳州市"
          },
          {
              "cityCode": "341700",
              "label": "池州市",
              "value": "池州市"
          },
          {
              "cityCode": "341800",
              "label": "宣城市",
              "value": "宣城市"
          }
      ]
  },
  {
      "provinceCode": "350000",
      "label": "福建省",
      "value": "福建省",
      "children": [
          {
              "cityCode": "350100",
              "label": "福州市",
              "value": "福州市"
          },
          {
              "cityCode": "350200",
              "label": "厦门市",
              "value": "厦门市"
          },
          {
              "cityCode": "350300",
              "label": "莆田市",
              "value": "莆田市"
          },
          {
              "cityCode": "350400",
              "label": "三明市",
              "value": "三明市"
          },
          {
              "cityCode": "350500",
              "label": "泉州市",
              "value": "泉州市"
          },
          {
              "cityCode": "350600",
              "label": "漳州市",
              "value": "漳州市"
          },
          {
              "cityCode": "350700",
              "label": "南平市",
              "value": "南平市"
          },
          {
              "cityCode": "350800",
              "label": "龙岩市",
              "value": "龙岩市"
          },
          {
              "cityCode": "350900",
              "label": "宁德市",
              "value": "宁德市"
          }
      ]
  },
  {
      "provinceCode": "360000",
      "label": "江西省",
      "value": "江西省",
      "children": [
          {
              "cityCode": "360100",
              "label": "南昌市",
              "value": "南昌市"
          },
          {
              "cityCode": "360200",
              "label": "景德镇市",
              "value": "景德镇市"
          },
          {
              "cityCode": "360300",
              "label": "萍乡市",
              "value": "萍乡市"
          },
          {
              "cityCode": "360400",
              "label": "九江市",
              "value": "九江市"
          },
          {
              "cityCode": "360500",
              "label": "新余市",
              "value": "新余市"
          },
          {
              "cityCode": "360600",
              "label": "鹰潭市",
              "value": "鹰潭市"
          },
          {
              "cityCode": "360700",
              "label": "赣州市",
              "value": "赣州市"
          },
          {
              "cityCode": "360800",
              "label": "吉安市",
              "value": "吉安市"
          },
          {
              "cityCode": "360900",
              "label": "宜春市",
              "value": "宜春市"
          },
          {
              "cityCode": "361000",
              "label": "抚州市",
              "value": "抚州市"
          },
          {
              "cityCode": "361100",
              "label": "上饶市",
              "value": "上饶市"
          }
      ]
  },
  {
      "provinceCode": "370000",
      "label": "山东省",
      "value": "山东省",
      "children": [
          {
              "cityCode": "370100",
              "label": "济南市",
              "value": "济南市"
          },
          {
              "cityCode": "370200",
              "label": "青岛市",
              "value": "青岛市"
          },
          {
              "cityCode": "370300",
              "label": "淄博市",
              "value": "淄博市"
          },
          {
              "cityCode": "370400",
              "label": "枣庄市",
              "value": "枣庄市"
          },
          {
              "cityCode": "370500",
              "label": "东营市",
              "value": "东营市"
          },
          {
              "cityCode": "370600",
              "label": "烟台市",
              "value": "烟台市"
          },
          {
              "cityCode": "370700",
              "label": "潍坊市",
              "value": "潍坊市"
          },
          {
              "cityCode": "370800",
              "label": "济宁市",
              "value": "济宁市"
          },
          {
              "cityCode": "370900",
              "label": "泰安市",
              "value": "泰安市"
          },
          {
              "cityCode": "371000",
              "label": "威海市",
              "value": "威海市"
          },
          {
              "cityCode": "371100",
              "label": "日照市",
              "value": "日照市"
          },
          {
              "cityCode": "371200",
              "label": "莱芜市",
              "value": "莱芜市"
          },
          {
              "cityCode": "371300",
              "label": "临沂市",
              "value": "临沂市"
          },
          {
              "cityCode": "371400",
              "label": "德州市",
              "value": "德州市"
          },
          {
              "cityCode": "371500",
              "label": "聊城市",
              "value": "聊城市"
          },
          {
              "cityCode": "371600",
              "label": "滨州市",
              "value": "滨州市"
          },
          {
              "cityCode": "371700",
              "label": "菏泽市",
              "value": "菏泽市"
          }
      ]
  },
  {
      "provinceCode": "410000",
      "label": "河南省",
      "value": "河南省",
      "children": [
          {
              "cityCode": "410100",
              "label": "郑州市",
              "value": "郑州市"
          },
          {
              "cityCode": "410200",
              "label": "开封市",
              "value": "开封市"
          },
          {
              "cityCode": "410300",
              "label": "洛阳市",
              "value": "洛阳市"
          },
          {
              "cityCode": "410400",
              "label": "平顶山市",
              "value": "平顶山市"
          },
          {
              "cityCode": "410500",
              "label": "安阳市",
              "value": "安阳市"
          },
          {
              "cityCode": "410600",
              "label": "鹤壁市",
              "value": "鹤壁市"
          },
          {
              "cityCode": "410700",
              "label": "新乡市",
              "value": "新乡市"
          },
          {
              "cityCode": "410800",
              "label": "焦作市",
              "value": "焦作市"
          },
          {
              "cityCode": "410900",
              "label": "濮阳市",
              "value": "濮阳市"
          },
          {
              "cityCode": "411000",
              "label": "许昌市",
              "value": "许昌市"
          },
          {
              "cityCode": "411100",
              "label": "漯河市",
              "value": "漯河市"
          },
          {
              "cityCode": "411200",
              "label": "三门峡市",
              "value": "三门峡市"
          },
          {
              "cityCode": "411300",
              "label": "南阳市",
              "value": "南阳市"
          },
          {
              "cityCode": "411400",
              "label": "商丘市",
              "value": "商丘市"
          },
          {
              "cityCode": "411500",
              "label": "信阳市",
              "value": "信阳市"
          },
          {
              "cityCode": "411600",
              "label": "周口市",
              "value": "周口市"
          },
          {
              "cityCode": "411700",
              "label": "驻马店市",
              "value": "驻马店市"
          },
          {
              "cityCode": "419000",
              "label": "河南省直辖县级行政区划",
              "value": "河南省直辖县级行政区划"
          }
      ]
  },
  {
      "provinceCode": "420000",
      "label": "湖北省",
      "value": "湖北省",
      "children": [
          {
              "cityCode": "420100",
              "label": "武汉市",
              "value": "武汉市"
          },
          {
              "cityCode": "420200",
              "label": "黄石市",
              "value": "黄石市"
          },
          {
              "cityCode": "420300",
              "label": "十堰市",
              "value": "十堰市"
          },
          {
              "cityCode": "420500",
              "label": "宜昌市",
              "value": "宜昌市"
          },
          {
              "cityCode": "420600",
              "label": "襄樊市",
              "value": "襄樊市"
          },
          {
              "cityCode": "420700",
              "label": "鄂州市",
              "value": "鄂州市"
          },
          {
              "cityCode": "420800",
              "label": "荆门市",
              "value": "荆门市"
          },
          {
              "cityCode": "420900",
              "label": "孝感市",
              "value": "孝感市"
          },
          {
              "cityCode": "421000",
              "label": "荆州市",
              "value": "荆州市"
          },
          {
              "cityCode": "421100",
              "label": "黄冈市",
              "value": "黄冈市"
          },
          {
              "cityCode": "421200",
              "label": "咸宁市",
              "value": "咸宁市"
          },
          {
              "cityCode": "421300",
              "label": "随州市",
              "value": "随州市"
          },
          {
              "cityCode": "422800",
              "label": "恩施土家族苗族自治州",
              "value": "恩施土家族苗族自治州"
          },
          {
              "cityCode": "429000",
              "label": "省直辖行政单位",
              "value": "省直辖行政单位"
          }
      ]
  },
  {
      "provinceCode": "430000",
      "label": "湖南省",
      "value": "湖南省",
      "children": [
          {
              "cityCode": "430100",
              "label": "长沙市",
              "value": "长沙市"
          },
          {
              "cityCode": "430200",
              "label": "株洲市",
              "value": "株洲市"
          },
          {
              "cityCode": "430300",
              "label": "湘潭市",
              "value": "湘潭市"
          },
          {
              "cityCode": "430400",
              "label": "衡阳市",
              "value": "衡阳市"
          },
          {
              "cityCode": "430500",
              "label": "邵阳市",
              "value": "邵阳市"
          },
          {
              "cityCode": "430600",
              "label": "岳阳市",
              "value": "岳阳市"
          },
          {
              "cityCode": "430700",
              "label": "常德市",
              "value": "常德市"
          },
          {
              "cityCode": "430800",
              "label": "张家界市",
              "value": "张家界市"
          },
          {
              "cityCode": "430900",
              "label": "益阳市",
              "value": "益阳市"
          },
          {
              "cityCode": "431000",
              "label": "郴州市",
              "value": "郴州市"
          },
          {
              "cityCode": "431100",
              "label": "永州市",
              "value": "永州市"
          },
          {
              "cityCode": "431200",
              "label": "怀化市",
              "value": "怀化市"
          },
          {
              "cityCode": "431300",
              "label": "娄底市",
              "value": "娄底市"
          },
          {
              "cityCode": "433100",
              "label": "湘西土家族苗族自治州",
              "value": "湘西土家族苗族自治州"
          }
      ]
  },
  {
      "provinceCode": "440000",
      "label": "广东省",
      "value": "广东省",
      "children": [
          {
              "cityCode": "440100",
              "label": "广州市",
              "value": "广州市"
          },
          {
              "cityCode": "440200",
              "label": "韶关市",
              "value": "韶关市"
          },
          {
              "cityCode": "440300",
              "label": "深圳市",
              "value": "深圳市"
          },
          {
              "cityCode": "440400",
              "label": "珠海市",
              "value": "珠海市"
          },
          {
              "cityCode": "440500",
              "label": "汕头市",
              "value": "汕头市"
          },
          {
              "cityCode": "440600",
              "label": "佛山市",
              "value": "佛山市"
          },
          {
              "cityCode": "440700",
              "label": "江门市",
              "value": "江门市"
          },
          {
              "cityCode": "440800",
              "label": "湛江市",
              "value": "湛江市"
          },
          {
              "cityCode": "440900",
              "label": "茂名市",
              "value": "茂名市"
          },
          {
              "cityCode": "441200",
              "label": "肇庆市",
              "value": "肇庆市"
          },
          {
              "cityCode": "441300",
              "label": "惠州市",
              "value": "惠州市"
          },
          {
              "cityCode": "441400",
              "label": "梅州市",
              "value": "梅州市"
          },
          {
              "cityCode": "441500",
              "label": "汕尾市",
              "value": "汕尾市"
          },
          {
              "cityCode": "441600",
              "label": "河源市",
              "value": "河源市"
          },
          {
              "cityCode": "441700",
              "label": "阳江市",
              "value": "阳江市"
          },
          {
              "cityCode": "441800",
              "label": "清远市",
              "value": "清远市"
          },
          {
              "cityCode": "441900",
              "label": "东莞市",
              "value": "东莞市"
          },
          {
              "cityCode": "442000",
              "label": "中山市",
              "value": "中山市"
          },
          {
              "cityCode": "445100",
              "label": "潮州市",
              "value": "潮州市"
          },
          {
              "cityCode": "445200",
              "label": "揭阳市",
              "value": "揭阳市"
          },
          {
              "cityCode": "445300",
              "label": "云浮市",
              "value": "云浮市"
          }
      ]
  },
  {
      "provinceCode": "450000",
      "label": "广西壮族自治区",
      "value": "广西壮族自治区",
      "children": [
          {
              "cityCode": "450100",
              "label": "南宁市",
              "value": "南宁市"
          },
          {
              "cityCode": "450200",
              "label": "柳州市",
              "value": "柳州市"
          },
          {
              "cityCode": "450300",
              "label": "桂林市",
              "value": "桂林市"
          },
          {
              "cityCode": "450400",
              "label": "梧州市",
              "value": "梧州市"
          },
          {
              "cityCode": "450500",
              "label": "北海市",
              "value": "北海市"
          },
          {
              "cityCode": "450600",
              "label": "防城港市",
              "value": "防城港市"
          },
          {
              "cityCode": "450700",
              "label": "钦州市",
              "value": "钦州市"
          },
          {
              "cityCode": "450800",
              "label": "贵港市",
              "value": "贵港市"
          },
          {
              "cityCode": "450900",
              "label": "玉林市",
              "value": "玉林市"
          },
          {
              "cityCode": "451000",
              "label": "百色市",
              "value": "百色市"
          },
          {
              "cityCode": "451100",
              "label": "贺州市",
              "value": "贺州市"
          },
          {
              "cityCode": "451200",
              "label": "河池市",
              "value": "河池市"
          },
          {
              "cityCode": "451300",
              "label": "来宾市",
              "value": "来宾市"
          },
          {
              "cityCode": "451400",
              "label": "崇左市",
              "value": "崇左市"
          }
      ]
  },
  {
      "provinceCode": "460000",
      "label": "海南省",
      "value": "海南省",
      "children": [
          {
              "cityCode": "460100",
              "label": "海口市",
              "value": "海口市"
          },
          {
              "cityCode": "460200",
              "label": "三亚市",
              "value": "三亚市"
          },
          {
              "cityCode": "469000",
              "label": "省直辖县级行政单位",
              "value": "省直辖县级行政单位"
          }
      ]
  },
  {
      "provinceCode": "500000",
      "label": "重庆市",
      "value": "重庆市",
      "children": [
          {
              "cityCode": "500100",
              "label": "重庆市",
              "value": "重庆市"
          },
          {
              "cityCode": "500200",
              "label": "重庆市辖县",
              "value": "重庆市辖县"
          }
      ]
  },
  {
      "provinceCode": "510000",
      "label": "四川省",
      "value": "四川省",
      "children": [
          {
              "cityCode": "510100",
              "label": "成都市",
              "value": "成都市"
          },
          {
              "cityCode": "510300",
              "label": "自贡市",
              "value": "自贡市"
          },
          {
              "cityCode": "510400",
              "label": "攀枝花市",
              "value": "攀枝花市"
          },
          {
              "cityCode": "510500",
              "label": "泸州市",
              "value": "泸州市"
          },
          {
              "cityCode": "510600",
              "label": "德阳市",
              "value": "德阳市"
          },
          {
              "cityCode": "510700",
              "label": "绵阳市",
              "value": "绵阳市"
          },
          {
              "cityCode": "510800",
              "label": "广元市",
              "value": "广元市"
          },
          {
              "cityCode": "510900",
              "label": "遂宁市",
              "value": "遂宁市"
          },
          {
              "cityCode": "511000",
              "label": "内江市",
              "value": "内江市"
          },
          {
              "cityCode": "511100",
              "label": "乐山市",
              "value": "乐山市"
          },
          {
              "cityCode": "511300",
              "label": "南充市",
              "value": "南充市"
          },
          {
              "cityCode": "511400",
              "label": "眉山市",
              "value": "眉山市"
          },
          {
              "cityCode": "511500",
              "label": "宜宾市",
              "value": "宜宾市"
          },
          {
              "cityCode": "511600",
              "label": "广安市",
              "value": "广安市"
          },
          {
              "cityCode": "511700",
              "label": "达州市",
              "value": "达州市"
          },
          {
              "cityCode": "511800",
              "label": "雅安市",
              "value": "雅安市"
          },
          {
              "cityCode": "511900",
              "label": "巴中市",
              "value": "巴中市"
          },
          {
              "cityCode": "512000",
              "label": "资阳市",
              "value": "资阳市"
          },
          {
              "cityCode": "513200",
              "label": "阿坝藏族羌族自治州",
              "value": "阿坝藏族羌族自治州"
          },
          {
              "cityCode": "513300",
              "label": "甘孜藏族自治州",
              "value": "甘孜藏族自治州"
          },
          {
              "cityCode": "513400",
              "label": "凉山彝族自治州",
              "value": "凉山彝族自治州"
          }
      ]
  },
  {
      "provinceCode": "520000",
      "label": "贵州省",
      "value": "贵州省",
      "children": [
          {
              "cityCode": "520100",
              "label": "贵阳市",
              "value": "贵阳市"
          },
          {
              "cityCode": "520200",
              "label": "六盘水市",
              "value": "六盘水市"
          },
          {
              "cityCode": "520300",
              "label": "遵义市",
              "value": "遵义市"
          },
          {
              "cityCode": "520400",
              "label": "安顺市",
              "value": "安顺市"
          },
          {
              "cityCode": "522200",
              "label": "铜仁地区",
              "value": "铜仁地区"
          },
          {
              "cityCode": "522300",
              "label": "黔西南布依族苗族自治州",
              "value": "黔西南布依族苗族自治州"
          },
          {
              "cityCode": "522400",
              "label": "毕节地区",
              "value": "毕节地区"
          },
          {
              "cityCode": "522600",
              "label": "黔东南苗族侗族自治州",
              "value": "黔东南苗族侗族自治州"
          },
          {
              "cityCode": "522700",
              "label": "黔南布依族苗族自治州",
              "value": "黔南布依族苗族自治州"
          }
      ]
  },
  {
      "provinceCode": "530000",
      "label": "云南省",
      "value": "云南省",
      "children": [
          {
              "cityCode": "530100",
              "label": "昆明市",
              "value": "昆明市"
          },
          {
              "cityCode": "530300",
              "label": "曲靖市",
              "value": "曲靖市"
          },
          {
              "cityCode": "530400",
              "label": "玉溪市",
              "value": "玉溪市"
          },
          {
              "cityCode": "530500",
              "label": "保山市",
              "value": "保山市"
          },
          {
              "cityCode": "530600",
              "label": "昭通市",
              "value": "昭通市"
          },
          {
              "cityCode": "530700",
              "label": "丽江市",
              "value": "丽江市"
          },
          {
              "cityCode": "530800",
              "label": "思茅市",
              "value": "思茅市"
          },
          {
              "cityCode": "530900",
              "label": "临沧市",
              "value": "临沧市"
          },
          {
              "cityCode": "532300",
              "label": "楚雄彝族自治州",
              "value": "楚雄彝族自治州"
          },
          {
              "cityCode": "532500",
              "label": "红河哈尼族彝族自治州",
              "value": "红河哈尼族彝族自治州"
          },
          {
              "cityCode": "532600",
              "label": "文山壮族苗族自治州",
              "value": "文山壮族苗族自治州"
          },
          {
              "cityCode": "532800",
              "label": "西双版纳傣族自治州",
              "value": "西双版纳傣族自治州"
          },
          {
              "cityCode": "532900",
              "label": "大理白族自治州",
              "value": "大理白族自治州"
          },
          {
              "cityCode": "533100",
              "label": "德宏傣族景颇族自治州",
              "value": "德宏傣族景颇族自治州"
          },
          {
              "cityCode": "533300",
              "label": "怒江傈僳族自治州",
              "value": "怒江傈僳族自治州"
          },
          {
              "cityCode": "533400",
              "label": "迪庆藏族自治州",
              "value": "迪庆藏族自治州"
          }
      ]
  },
  {
      "provinceCode": "540000",
      "label": "西藏自治区",
      "value": "西藏自治区",
      "children": [
          {
              "cityCode": "540100",
              "label": "拉萨市",
              "value": "拉萨市"
          },
          {
              "cityCode": "542100",
              "label": "昌都地区",
              "value": "昌都地区"
          },
          {
              "cityCode": "542200",
              "label": "山南地区",
              "value": "山南地区"
          },
          {
              "cityCode": "542300",
              "label": "日喀则地区",
              "value": "日喀则地区"
          },
          {
              "cityCode": "542400",
              "label": "那曲地区",
              "value": "那曲地区"
          },
          {
              "cityCode": "542500",
              "label": "阿里地区",
              "value": "阿里地区"
          },
          {
              "cityCode": "542600",
              "label": "林芝地区",
              "value": "林芝地区"
          }
      ]
  },
  {
      "provinceCode": "610000",
      "label": "陕西省",
      "value": "陕西省",
      "children": [
          {
              "cityCode": "610100",
              "label": "西安市",
              "value": "西安市"
          },
          {
              "cityCode": "610200",
              "label": "铜川市",
              "value": "铜川市"
          },
          {
              "cityCode": "610300",
              "label": "宝鸡市",
              "value": "宝鸡市"
          },
          {
              "cityCode": "610400",
              "label": "咸阳市",
              "value": "咸阳市"
          },
          {
              "cityCode": "610500",
              "label": "渭南市",
              "value": "渭南市"
          },
          {
              "cityCode": "610600",
              "label": "延安市",
              "value": "延安市"
          },
          {
              "cityCode": "610700",
              "label": "汉中市",
              "value": "汉中市"
          },
          {
              "cityCode": "610800",
              "label": "榆林市",
              "value": "榆林市"
          },
          {
              "cityCode": "610900",
              "label": "安康市",
              "value": "安康市"
          },
          {
              "cityCode": "611000",
              "label": "商洛市",
              "value": "商洛市"
          }
      ]
  },
  {
      "provinceCode": "620000",
      "label": "甘肃省",
      "value": "甘肃省",
      "children": [
          {
              "cityCode": "620100",
              "label": "兰州市",
              "value": "兰州市"
          },
          {
              "cityCode": "620200",
              "label": "嘉峪关市",
              "value": "嘉峪关市"
          },
          {
              "cityCode": "620300",
              "label": "金昌市",
              "value": "金昌市"
          },
          {
              "cityCode": "620400",
              "label": "白银市",
              "value": "白银市"
          },
          {
              "cityCode": "620500",
              "label": "天水市",
              "value": "天水市"
          },
          {
              "cityCode": "620600",
              "label": "武威市",
              "value": "武威市"
          },
          {
              "cityCode": "620700",
              "label": "张掖市",
              "value": "张掖市"
          },
          {
              "cityCode": "620800",
              "label": "平凉市",
              "value": "平凉市"
          },
          {
              "cityCode": "620900",
              "label": "酒泉市",
              "value": "酒泉市"
          },
          {
              "cityCode": "621000",
              "label": "庆阳市",
              "value": "庆阳市"
          },
          {
              "cityCode": "621100",
              "label": "定西市",
              "value": "定西市"
          },
          {
              "cityCode": "621200",
              "label": "陇南市",
              "value": "陇南市"
          },
          {
              "cityCode": "622900",
              "label": "临夏回族自治州",
              "value": "临夏回族自治州"
          },
          {
              "cityCode": "623000",
              "label": "甘南藏族自治州",
              "value": "甘南藏族自治州"
          }
      ]
  },
  {
      "provinceCode": "630000",
      "label": "青海省",
      "value": "青海省",
      "children": [
          {
              "cityCode": "630100",
              "label": "西宁市",
              "value": "西宁市"
          },
          {
              "cityCode": "632100",
              "label": "海东地区",
              "value": "海东地区"
          },
          {
              "cityCode": "632200",
              "label": "海北藏族自治州",
              "value": "海北藏族自治州"
          },
          {
              "cityCode": "632300",
              "label": "黄南藏族自治州",
              "value": "黄南藏族自治州"
          },
          {
              "cityCode": "632500",
              "label": "海南藏族自治州",
              "value": "海南藏族自治州"
          },
          {
              "cityCode": "632600",
              "label": "果洛藏族自治州",
              "value": "果洛藏族自治州"
          },
          {
              "cityCode": "632700",
              "label": "玉树藏族自治州",
              "value": "玉树藏族自治州"
          },
          {
              "cityCode": "632800",
              "label": "海西蒙古族藏族自治州",
              "value": "海西蒙古族藏族自治州"
          }
      ]
  },
  {
      "provinceCode": "640000",
      "label": "宁夏回族自治区",
      "value": "宁夏回族自治区",
      "children": [
          {
              "cityCode": "640100",
              "label": "银川市",
              "value": "银川市"
          },
          {
              "cityCode": "640200",
              "label": "石嘴山市",
              "value": "石嘴山市"
          },
          {
              "cityCode": "640300",
              "label": "吴忠市",
              "value": "吴忠市"
          },
          {
              "cityCode": "640400",
              "label": "固原市",
              "value": "固原市"
          },
          {
              "cityCode": "640500",
              "label": "中卫市",
              "value": "中卫市"
          }
      ]
  },
  {
      "provinceCode": "650000",
      "label": "新疆维吾尔自治区",
      "value": "新疆维吾尔自治区",
      "children": [
          {
              "cityCode": "650100",
              "label": "乌鲁木齐市",
              "value": "乌鲁木齐市"
          },
          {
              "cityCode": "650200",
              "label": "克拉玛依市",
              "value": "克拉玛依市"
          },
          {
              "cityCode": "652100",
              "label": "吐鲁番地区",
              "value": "吐鲁番地区"
          },
          {
              "cityCode": "652200",
              "label": "哈密地区",
              "value": "哈密地区"
          },
          {
              "cityCode": "652300",
              "label": "昌吉回族自治州",
              "value": "昌吉回族自治州"
          },
          {
              "cityCode": "652700",
              "label": "博尔塔拉蒙古自治州",
              "value": "博尔塔拉蒙古自治州"
          },
          {
              "cityCode": "652800",
              "label": "巴音郭楞蒙古自治州",
              "value": "巴音郭楞蒙古自治州"
          },
          {
              "cityCode": "652900",
              "label": "阿克苏地区",
              "value": "阿克苏地区"
          },
          {
              "cityCode": "653000",
              "label": "克孜勒苏柯尔克孜自治州",
              "value": "克孜勒苏柯尔克孜自治州"
          },
          {
              "cityCode": "653100",
              "label": "喀什地区",
              "value": "喀什地区"
          },
          {
              "cityCode": "653200",
              "label": "和田地区",
              "value": "和田地区"
          },
          {
              "cityCode": "654000",
              "label": "伊犁哈萨克自治州",
              "value": "伊犁哈萨克自治州"
          },
          {
              "cityCode": "654200",
              "label": "塔城地区",
              "value": "塔城地区"
          },
          {
              "cityCode": "654300",
              "label": "阿勒泰地区",
              "value": "阿勒泰地区"
          },
          {
              "cityCode": "659000",
              "label": "省直辖行政单位",
              "value": "省直辖行政单位"
          }
      ]
  }
];